import styled from 'styled-components'

export const Typography = styled.div`
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    letter-spacing: -0.005em;
    text-align: center;
`
